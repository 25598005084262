:root{
    --color-212529: #212529;
    --color-1677FF: #1677FF;
    --color-47ad24: #47ad24;
    --color-e52e2e: #e52e2e;
    --color-828599: #828599;
    --color-3d464d: #3d464d;
    --color-ced4da: #ced4da;
}

.green{
    color: var(--color-47ad24);
    font-size: 14px;
}

.red{
    color: var(--color-e52e2e);
    font-size: 14px;
}

.quill{
    background-color: #fff;
}

.form-control{
    box-shadow: none;
    border-color: var(--color-ced4da);
}

.title{
    font: 28px;
    font-family: Roboto !important;
    color: var(--color-212529);
}

.sub-title{
    font: 24px;
    font-family: Roboto !important;
    color: var(--color-212529) !important;
}

.desc{
    font: 14px;
    font-family: Roboto !important;
    color: var(--color-828599);
}
