body{
    font-family: Roboto, sans-serif;
}

.ant-layout{
    min-height: 100vh;
}

.ant-layout .ant-layout-sider-children {
    background-color: #000;
    color: #fff;
}

.ant-menu-title-content{
    font-size: 16px;
}

.ant-layout-sider{
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
}

.ant-layout-sider-collapsed{
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
}

.sm-logo{
    display: none;
}

.ant-layout-sider-collapsed .sm-logo{
    display: block;
}


.ant-layout-sider-collapsed .lg-logo{
    display: none;
}

.logo{
    background-color: #1677FF;
    margin: 0;
    height: 67px;
}

main{
    height: 85vh;
    overflow-y: scroll;
    background-color: rgb(219, 217, 217) !important;
}

main::-webkit-scrollbar{
    width: 0px;
}

.ant-layout-header h5{
    line-height: 20px;
    font-size: 14px;
}

.ant-layout-header p{
    line-height: 20px;
    font-size: 13px;
}

.ant-layout-header div .badge{
    top: 16px;
    right: -6px;
}

.error{
    color: red;
    font-size: 14px;
}

.selectedProductColor{
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}